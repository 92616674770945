<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
      <template v-if="isListDetail">
       <div class="page-title pt-0 pb-3">
          <router-link :to="{ name: 'RanapPasienPerawatan' }" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </router-link>
        </div>
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg-white">
                <div class="d-flex align-items-center">
                  <h5 class="card-title font-weight-semibold mr-auto">Tindakan Radiologi</h5>
                  <div class="col-md-auto">
                      <div class="form-group mb-0">
                          <button @click="apiGet" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                      </div>
                  </div>
                  <a href="javascript:;" @click="addTindakan()" class="btn bg-blue-600 btn-labeled btn-labeled-left">
                    <b><i class="icon-plus2"></i></b>Buat Permintaan</a>
                </div>
              </div>
              <table class="table table-bordered table-sm patient-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Nama & No. RM</th>
                    <th>Diagnosa</th>
                    <th>Tindakan</th>
                    <th>Tanggal Tindakan</th>
                    <th>Status</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v,k) in (dataTindakanRadiologi||[])" >
                    <tr :class="v.arantr_have_nilai_kritis_radio == 'Y'?'kritis-animate':''" :key="k">
                      <td>
                        <span>{{k+1}}</span>
                      </td>
                      <td>
                        <div class="d-flex flex-column align-items-start">
                          <a href="javascript:;" class="font-weight-semibold border-bottom">{{row.ap_fullname||"-"}}</a>
                          <small>{{row.ap_code}}</small>
                          <small class="text-secondary">Terdaftar {{rowReg.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                        </div>
                      </td>
                      <td>
                        {{v.arantr_diagnosa||"-"}}
                      </td>
                      <td>
                        {{v.arantr_tindakan||"-"}}
                      </td>
                      <td>
                        <span v-if="v.arantr_submit_date">
                          {{v.arantr_submit_date | moment("DD MMMM YYYY, HH:mm")}}                    
                        </span>
                        <span v-else> - </span>
                        
                        <small class="font-weight-semibold" v-if="v.input_by"> dibuat oleh {{v.input_by}}</small>
                      </td>
                      <td>
                        <template v-if="v.arantr_is_draft == 'Y'">
                          <span class="badge bg-warning mb-1">Draft </span>
                        </template>
                        <template v-else>
                          <div v-b-tooltip.hover title="Status Radiologi">
                            <span v-if="v.arantr_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                            <span v-else-if="v.arantr_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                            <span v-else class="badge bg-success mb-1">Selesai </span>
                          </div>
                        </template>
                        <div v-if="v.arantr_is_have_transfer_intra != 'Y' && (v.arantr_is_imported != 'Y' && v.arantr_is_imported_ppa != 'Y')" class="badge bg-warning mb-1">Isi Form Transfer Intra</div>
                        
                        <div v-if="v.arantr_is_menunggu_hasil == 'Y'" class="badge bg-warning mb-1">Hasil Pemeriksaan Belum Keluar</div>
                        
                        <span v-if="v.arantr_have_nilai_kritis_radio == 'Y'" class="badge bg-danger mb-1">Kritis </span>
                      </td>
                      <td>
                        <a href="javascript:;" v-if="v.arantr_is_draft !== 'Y' && moduleRole('view')"  @click="toModal(v,'xl','Detail Tindakan Radiologi','DetailTindakanRadiologi')" 
                        class="btn rounded-round btn-sm btn-icon alpha-blue border-blue" v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye"></i></a>
                        
                        <template v-if="v.arantr_status != 'DONE'">
                          <a href="javascript:;" v-if="v.arantr_is_draft !== 'Y' && selectedOperasiInformConsent(v) && moduleRole('inform_consent')" @click="toModal(v,'xl','Persetujuan Tindakan Kedokteran (Informed Consent)','FormInformConsent')" class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800" v-b-tooltip.hover title="Inform Consent">
                            <i class="fas fa-notes-medical"></i>
                          </a>
                          
                          <a href="javascript:;" v-if="v.arantr_is_draft !== 'Y' && moduleRole('transfer_intra')" @click="toForm(v,'FormTransferIntra')" class="btn rounded-round btn-sm btn-icon alpha-warning border-warning" v-b-tooltip.hover :title="(v.arantr_is_have_transfer_intra == 'Y' ? 'Edit' : 'Buat') + ' Form Transfer Intra'"><i class="icon-pencil4"></i></a>

                          <a href="javascript:;" v-if="moduleRole('edit')" @click="toForm(v)" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover title="Edit"><i class="icon-pencil4"></i></a>

                          <a href="javascript:;" v-if="v.arantr_is_draft == 'Y' && moduleRole('hapus')" @click="hapusTindakan(v)" class="btn rounded-round btn-sm btn-icon alpha-danger border-danger" v-b-tooltip.hover title="Hapus Tindakan"><i class="icon-bin"></i></a>
                        </template>
                        
                        <a href="javascript:;" v-if="v.arantr_have_nilai_kritis_radio == 'Y'" @click="changeStatusNilaiKritis(v)" class="btn rounded-round btn-sm btn-icon alpha-danger border-danger" v-b-tooltip.hover :title="'Hentikan Kritis'"><i class="fa-solid fa-hand"></i></a>
                      </td>
                    </tr>
                  </template>
                  <tr v-if="!(dataTindakanRadiologi||[]).length">
                    <td colspan="99" class="text-center">Tidak ada Data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <b-modal
          v-model="openModal"
          :size="modalConfig.size"
          no-close-on-backdrop
          hide-footer
          :title="modalConfig.title" 
        >     
          <FormInformConsent v-if="isModal == 'FormInformConsent'" />     
          <DetailTindakanRadiologi v-if="isModal == 'DetailTindakanRadiologi'" />     
        </b-modal>

      </template>
      <template v-else>
        <FormTransferIntra v-if="formName == 'FormTransferIntra'"/>
        <Form v-else /> 
      </template>
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import Form from './Form.vue'
  import FormTransferIntra from './FormTransferIntra.vue'
  import FormInformConsent from './FormInformConsent.vue'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  import DetailTindakanRadiologi from './DetailTindakanRadiologi.vue'

  export default {
    extends: GlobalVue,
    components:{Form,FormTransferIntra,FormInformConsent,PatientInfo,DetailTindakanRadiologi},
    data() {
      return {
        rowReg: {},
        dataTindakanRadiologi: [],
        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,
        
        patientData: {},
        loading: {
            patientInfo: false,
        },

        mDPJP: [],
      }
    },
    computed: {
      isListDetail(){ return !this.$route.params.radiologiNo },  
      formName(){ return this.$route.query.form },    
    },
    methods: {
      changeStatusNilaiKritis(row){
        this.$swal({
          title: `Hasil kritis sudah dilaporkan?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText:  `Ya`,
          cancelButtonText:  `Tidak, kembali`,        
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.loadingOverlay=true
            Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'change-status-nilai-kritis', id: row.arantr_id}}, 
              "POST"
            )
              .then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                  title: "Data Berhasil Disimpan",
                  icon: 'success',
                  confirmButtonText: 'Ok'
                })
                this.apiGet()
              })
              .catch(err=>{ 
                this.loadingOverlay = false 
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
          }
        })
      },
      selectedOperasiInformConsent(rowData) {
        let data = []
        for (let i = 0; i < (rowData.arantr_data || []).length; i++) {
          for (let j = 0; j < (rowData.arantr_data[i]['data'] || []).length; j++) {
            if (rowData.arantr_data[i]['data'][j]['selected']) {
              data.push(rowData.arantr_data[i]['data'][j]['inform_consent'])
            }
          }
        }
        return data.length ? true : false 
      },
      toModal(row,size,title,isModal){
        this.rowEdit = row
        this.modalConfig = {
          title: title,
          size: size
        }
        this.isModal = isModal
        this.openModal = true
      },
      
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
      },

      apiGet(params = {}, page = 1) {
        if (!this.pageSlug) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })
      },
      hapusTindakan(row){
        this.$swal({
          icon: 'warning',
          title: 'Hapus Tindakan Radiologi ?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'

        }).then(result => {
          if(result.value){
            this.loadingOverlay = true
            let data = {
              id : row.arantr_id
            }
            data.type = 'hapus-tindakan'
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
              this.loadingOverlay = false
              this.$swal({
                title: "Tindakan Berhasil DiHapus",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.apiGet()
            })
          }
        })
      },
      
      addTindakan(){
        this.loadingOverlay = true
        let data = {
          regId : this.rowReg.aranr_id
        }
        data.type = 'init-tindakan-radiologi'
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            this.loadingOverlay = false
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, radiologiNo: resp.arantr_id}})
            .catch(()=>{})
        })
      },
      toForm(row,form=''){
        if(form){
          this.$router.push({ 
          name: this.modulePage, 
          params: {pageSlug: this.rowReg.aranr_id, radiologiNo: row.arantr_id},
          query: {form: form}
          })
          .catch(()=>{})
        }else{
          this.$router.push({ name: this.modulePage, params: {pageSlug: this.rowReg.aranr_id, radiologiNo: row.arantr_id}})
          .catch(()=>{})
        }
      }

    },
    mounted() {
      this.apiGet()
      this.getPatientInfo()
    },
    watch:{
      $route(){
        this.apiGet()
      }
    }
  }
</script>