<template>
  <div class="card">
    <validation-observer ref="VFormTindakLanjut">
      <b-form @submit.prevent="doSubmit">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Radiologi</h6>
        </div>
        <div class="card-body p-3">
          <div>
            <label for="">Prioritas Pemeriksaan <strong class="text-danger">*</strong></label>
              <div>
                  <b-form-radio-group 
                  :options="Config.mr.configPrioritasPemeriksaan"
                  v-model="rowData['arantr_prioritas']" />
              </div>
              <VValidate :name="'Prioritas Pemeriksaan'"
                  v-model="rowData.arantr_prioritas"
                  :rules="toValidate(mrValidation.arantr_prioritas)" />
          </div>
        </div>
        <div class="card-body p-3">
          <div class="req-list-resume">
            <div class="row g-2">
            <template v-for="(v,k) in rowData.arantr_data">
                <div class="col-md-4 col-lg-3" :key="k+'radioData'"
                v-if="isShowHeadRadiologi(k)">
                <div class="card shadow-0 border">
                    <div class="card-header py-2 bg-light">
                    <h6 class="card-title text-uppercase font-weight-semibold">{{
            getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                    </div>
                    <div class="card-body py-2">
                    <template v-for="v1,k1 in (v.data||[])">
                        <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                        <i
                            class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                        <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                        
                        <a href="javascript:;" @click="openInformConsent(v1,k1,k,getConfigDynamic(Config.mr.KategoriRadiologi,v.head))" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo ml-2"  v-b-tooltip.hover :title="v1.inform_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                        <i class="icon-file-check2"></i>
                        </a>

                        <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,getConfigDynamic(Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                        <i class="icon-file-eye2"></i>
                        </a>

                        </div>
                    </template>
                    </div>
                </div>
                </div>
            </template>
            <div class="col-md-4 col-lg-3" v-if="rowData.arantr_data_lainnya">
                <div class="card shadow-0 border">
                <div class="card-header py-2 bg-light">
                    <h6 class="card-title text-uppercase font-weight-semibold">Item
                    Pemeriksaan Lainnya</h6>
                </div>
                <div class="card-body py-2">
                    <div class="req-list">
                    <div>
                        <i
                        class="icon-checkmark-circle text-success align-middle mr-1"></i>
                        <b class="align-middle font-weight-semibold">{{rowData.arantr_data_lainnya||"-"}}</b>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <span v-if="!selectedRadioInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
            </div>
          </div>
          <div class="text-center mt-3">
            <a href="javascript:;" @click="doOpenRadiologi" class="btn alpha-blue"><i class="icon-plus2 mr-2"></i>Tambah Pemeriksaan
              Radiologi</a>
          </div>
        </div>
        <div class="card-body p-3">
          <div>
            <label for="">Pasien Memiliki Alergi Terhadap Bahan Kontras/Zat Lainnya? <strong class="text-danger">*</strong></label>
            <div>
              <b-form-radio-group 
              :options="Config.mr.yesNoOptV2" v-model="rowData.arantr_alergi" />

              <VValidate :name="'Alergi Zat Lainnya'"
              v-model="rowData.arantr_alergi" :rules="toValidate(mrValidation.arantr_alergi)" />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>


    <b-modal v-model="openRadiologi" :title="'Pilih Item Pemeriksaan Radiologi'" size="xl" hide-footer>
      <div class="modal-header d-block p-0 mt-3">
          <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
          <li v-for="(v,k) in rowData.arantr_data" :key="k" @click="changeTabRadiologi(k)" class="nav-item">
              <a href="javascript:;" data-toggle="tab"
              :class="k == activeTabRadiologi ? 'nav-link active' : 'nav-link'">{{getConfigDynamic(Config.mr.KategoriRadiologi, v.head)||"-"}}</a>
          </li>
          </ul>
      </div>

      <div class="modal-body p-0 mt-3">
          <div class="tab-content">
          <div v-for="(v,k) in rowData.arantr_data" :key="k+'radform'"
              :class="k == activeTabRadiologi ? 'tab-pane fade show active' : 'tab-pane fade'">
              <div class="row sc-row g-0">
              <div class="col-md-4 col-lg-3" v-for="(v1,k1) in (v.data||[])" :key="k1+'radio'">
                  <div class="selection-control">
                  <b-form-checkbox @input="changeInpRadiologi($event,v1,k,k1)" :name="'tabrad'+String(v1.id)" v-model="v1['selected']">{{v1.text||"-"}}
                  </b-form-checkbox>
                  <b-form-input type="text" v-if="v1['selected']" v-model="v1['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                  </div>
              </div>
              </div>
          </div>
          </div>
          <div class="row ml-1 mt-2">
          <div class="col-md-4">
              <div class="form-group row">
              <label>Lainnya</label>
              <b-form-input v-model="rowData.arantr_data_lainnya" type="text" class="form-control" />
              </div>
          </div>
          </div>
      </div>

      <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
          <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
          <span class="m-0" v-if="selectedRadioInput">{{selectedRadioInput}}</span>
          <span v-else> - </span>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white">
          <div class="text-right">
          <button @click="openRadiologi = false" data-dismiss="modal" class="btn">Selesai</button>
          </div>
      </div>
    </b-modal>
    
    <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
        <small class="text-primary mt-2">Klik pada salah satu kolom 'Isi Informasi' untuk melakukan perubahan.</small>
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.pasienSetuju"
                            v-model="rowIC.aranic_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.persetujuanTindakan"
                            v-model="rowIC.aranic_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
    </validation-observer>
        
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>

  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  data() {
    return {
      rowData: {},

      openRadiologi: false,
      activeTabRadiologi: 0,

      openModalICDetail: false,
      openModalIC: false,
      rowIC: {
        aranic_is_pasien_setuju: null,
        aranic_persetujuan_tindakan: null
      },

    }
  },
  computed: {
    isParent(){
      return this.$parent.$parent
    },
    selectedRadioInput() {
      let data = []
      for (let i = 0; i < (this.rowData.arantr_data || []).length; i++) {
        for (let j = 0; j < (this.rowData.arantr_data[i]['data'] || []).length; j++) {
            if (this.rowData.arantr_data[i]['data'][j]['selected']) {
                data.push(this.rowData.arantr_data[i]['data'][j]['text'])
            }
        }
      }
      if (this.rowData.arantr_data_lainnya) {
        data.push(this.rowData.arantr_data_lainnya)
      }
      return data.join(", ")
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.radiologiNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.radiologiNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.radiologiNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },
    changeInpRadiologi(e,v,kU,k){
      if(!e && v.inform_consent){
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda yakin ingin menghapus tindakan ini, Tindakan telah mempunyai Informconsent?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
              if(result.value){
                  this.loadingOverlay = true                        
                  let data = {
                      type: "remove-ic-single",
                      id: v.inform_consent
                  }
                  Gen.apiRest(
                      "/do/"+this.modulePage, 
                      {
                          data: data
                      }, "POST"
                  ).then(()=>{
                      this.loadingOverlay = false                                            
                      this.rowData.arantr_data[kU]['data'][k]['inform_consent'] = null                    
                  })
              }
              else{
                  this.rowData.arantr_data[kU]['data'][k]['selected'] = true

              }
          })
      }
    },
    doOpenRadiologi() {
      this.openRadiologi = true
    },
    changeTabRadiologi(e) {
      this.activeTabRadiologi = e
    },
    toValidate(val){
      return {...val}
    },        
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    
    isShowHeadRadiologi(j) {
      let isData = 0
      for (let k = 0; k < (this.rowData.arantr_data[j]['data'] || []).length; k++) {
        if (this.rowData.arantr_data[j]['data'][k]['selected']) {
            isData += 1
        }
      }
      return isData
    },    
    isShowRadiologi(j, k) {
        let isData = 0
        if (this.rowData.arantr_data[j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
    },
    resetRadiologi() {
        for (let i = 0; i < (this.rowData.arantr_data || []).length; i++) {
            for (let j = 0; j < (this.rowData.arantr_data[i]['data'] || []).length; j++) {
                this.rowData.arantr_data[i]['data'][j]['selected'] = false
            }
        }
    },


    openInformConsentDetail(v,k1,k,kat){
        Gen.apiRest('/do/' + this.modulePage, {
            data: {
                type: 'get-data-ic-single',
                id: v.inform_consent,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.tindakan = v.text  + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent
              
            this.rowIC.k1 = k1
            this.rowIC.k = k

            this.openModalICDetail = true                   
        })
    },
    openInformConsent(v,k1,k,kat){
        if(!v.inform_consent){
        
            this.rowIC.aranic_is_pasien_setuju = null
            this.rowIC.aranic_persetujuan_tindakan = null

            this.rowIC.tindakan = v.text  + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent
            this.rowIC.k1 = k1
            this.rowIC.k = k

            this.rowIC.aranic_diagnosis = null
            this.rowIC.aranic_id = null
            this.rowIC.aranic_dasar_diagnosis = null
            this.rowIC.aranic_tindakan_dokter = null
            this.rowIC.aranic_indikasi_tindakan = null
            this.rowIC.aranic_tata_cara = null
            this.rowIC.aranic_tujuan = null
            this.rowIC.aranic_risiko = null
            this.rowIC.aranic_kompilasi = null
            this.rowIC.aranic_prognosis = null
            this.rowIC.aranic_alternatif = null
            this.rowIC.aranic_lainnya = null
  
        }else{  
            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'get-data-ic-single',
                    id: v.inform_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.tindakan = v.text + "("+kat+")"
                this.rowIC.id_tindakan = v.inform_consent   
                this.rowIC.k1 = k1
                this.rowIC.k = k         
            })
            // console.log(edit)
        }
        this.openModalIC = true
    },
    submitInformConcent(){
        this.$refs['VFormInformconsent'].validate().then(success => {
            if(success){
                this.openModalIC = false

                let data = this.rowIC
                data.type = 'submit-data-inform-consent-single'
                data.aranic_aranr_id = this.pageSlug
                data.aranic_name = this.rowIC.tindakan
                data.aranic_created_on = 'Radiologi'

                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + this.modulePage, {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.rowData['arantr_data'][this.rowIC.k]['data'][this.rowIC.k1]['inform_consent'] = res.data
                    this.loadingOverlay = false
                    this.autoSave(this.rowData)

                    this.rowIC.aranic_id = null
                    
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data:this.rowData}, 
                        "POST"
                    )
                    
                    return this.$swal({
                        title: "Data Informed Consent berhasil disimpan",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
    },
  
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      data.arantr_tindakan = this.selectedRadioInput

      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),


    doSubmit(){
      this.$refs['VFormTindakLanjut'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowData
              data.type = 'submit-data'
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                }).catch(err => {
                  this.loadingOverlay = false
                  if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
                })
              })
            }
          })
        }
        
      })
    }
  },
  mounted() {
    this.apiGetForm()
  },
  watch: {
    rowData: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    }
  }
}

</script>
