<template>
  <div class="card mb-2">
    <div class="card-header bg_head_panel">
      <div class="d-flex align-items-center">
        <h6 class="card-title font-weight-semibold">Form Transfer Intra RS (Penunjang)</h6>
      </div>
    </div>

    <validation-observer ref="VFormTindakLanjut">
      <b-form @submit.prevent="doSubmit">
        <div class="form-underlying card-body p-3">
          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra1">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>S</h2>
                  </div>
                  <strong class="ml-2">Situation</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra1">
              <table class="table table-bordered">
                <tr>
                  <td style="width:33%">
                    <div class="result_tab">
                      <h4>Nama</h4>
                      <p>{{isParent.row.ap_fullname||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%">
                    <div class="result_tab">
                      <h4>No. Rekam Medis</h4>
                      <p>{{isParent.row.ap_code||"-"}}</p>
                    </div>
                  </td>
                  <td style="width:33%">
                    <div class="result_tab">
                      <h4>NIK</h4>
                      <p>{{isParent.row.ap_nik||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tanggal Lahir</h4>
                      <p>{{isParent.row.ap_dob | moment("DD MMMM YYYY")}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>DPJP</h4>
                      <p>{{isParent.rowReg.bu_full_name||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jam Datang</h4>
                      <p>{{rowTransferIntra.arantir_jam_datang||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Diagnosa</h4>
                      <p>{{rowTransferIntra.arantir_diagnosa||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Kondisi Saat Ini</h4>
                      <p>{{rowTransferIntra.arantir_kondisi_saat_ini||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4></h4>
                      <p></p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Dari Ruang</h4>
                      <p>{{rowTransferIntra.arantir_dari_ruang||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Ke Ruang</h4>
                      <p>{{rowTransferIntra.arantir_ke_ruang||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jam Pindah</h4>
                      <p>{{jamPindah||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra2">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>B</h2>
                  </div>
                  <strong class="ml-2">Background</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra2">
              <div class="row">
                <div class="col-lg-8">
                  <div class="row">

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Keluhan Utama</label>
                        <b-form-textarea v-model="rowTransferIntra.arantir_keluhan_utama" name="" id="" rows="3"
                          class="form-control" />

                        <VValidate name="Keluhan Utama" v-model="rowTransferIntra.arantir_keluhan_utama"
                          :rules="toValidate(mrValidationTransferIntra.arantir_keluhan_utama)" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Riwayat Penyakit</label>
                        <b-form-textarea v-model="rowTransferIntra.arantir_riwayat_penyakit" name="" id="" rows="3"
                          class="form-control" value="Obat: Amoxicilin, Amlodipin. Lainnya: Dingin" />

                        <VValidate name="Riwayat Penyakit" v-model="rowTransferIntra.arantir_riwayat_penyakit"
                          :rules="toValidate(mrValidationTransferIntra.arantir_riwayat_penyakit)" />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Intervensi yang Telah Dilakukan</label>
                        <b-form-textarea v-model="rowTransferIntra.arantir_background_intervensi" name="" id=""
                          cols="30" rows="3" class="form-control" />

                        <VValidate name="Intervensi yang Telah Dilakukan"
                          v-model="rowTransferIntra.arantir_background_intervensi"
                          :rules="toValidate(mrValidationTransferIntra.arantir_background_intervensi)" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="asuhanPembedahan">Riwayat Pembedahan </label>
                        <b-form-textarea v-model="rowTransferIntra.arantir_riwayat_pembedahan" name="asuhanPembedahan"
                          id="asuhanPembedahan" rows="3" class="form-control form-control-sm" />

                        <VValidate name="Riwayat Pembedahan" v-model="rowTransferIntra.arantir_riwayat_pembedahan"
                          :rules="toValidate(mrValidationTransferIntra.arantir_riwayat_pembedahan)" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Obat-obatan yang Diberikan</label>
                        <b-form-textarea v-model="rowTransferIntra.arantir_obat_obatan_yang_diberikan" name="" id=""
                          rows="3" class="form-control" />

                        <VValidate name="Obat-obatan yang Diberikan"
                          v-model="rowTransferIntra.arantir_obat_obatan_yang_diberikan"
                          :rules="toValidate(mrValidationTransferIntra.arantir_obat_obatan_yang_diberikan)" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Cairan Parenteral yang Diberikan</label>
                        <b-form-textarea v-model="rowTransferIntra.arantir_cairan_parental_yang_diberikan" name="" id=""
                          rows="3" class="form-control" />

                        <VValidate name="Cairan Parenteral yang Diberikan"
                          v-model="rowTransferIntra.arantir_cairan_parental_yang_diberikan"
                          :rules="toValidate(mrValidationTransferIntra.arantir_cairan_parental_yang_diberikan)" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Riwayat Alergi</label>
                        <b-form-textarea v-model="rowTransferIntra.arantir_riwayat_alergi" name="" id="" rows="3"
                          class="form-control" value="Obat: Amoxicilin, Amlodipin. Lainnya: Dingin" />

                        <VValidate name="Riwayat Alergi" v-model="rowTransferIntra.arantir_riwayat_alergi"
                          :rules="toValidate(mrValidationTransferIntra.arantir_riwayat_alergi)" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label for="">Tindakan Invasif</label>
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th width="48">#</th>
                          <th>Tindakan</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (rowTransferIntra.arantir_tindakan_invasif||[])" :key="k">
                          <td>{{k+1}}</td>
                          <td>
                            <input type="text" v-model="rowTransferIntra.arantir_tindakan_invasif[k]"
                              class="form-control form-control-sm">
                            <VValidate :name="'Tindakan Invasif '+(k+1)"
                              v-model="rowTransferIntra.arantir_tindakan_invasif[k]" :rules="{required : 1}" />
                          </td>
                          <td><a href="javascript:;" class="list-icons-item"
                              @click="rowTransferIntra.arantir_tindakan_invasif.splice(k,1)" data-toggle="tooltip"
                              data-placement="top" title="Delete"><i class="icon-bin"></i></a></td>
                        </tr>

                        <tr v-if="!(rowTransferIntra.arantir_tindakan_invasif||[]).length">
                          <td colspan="3" class="text-center">
                            Tidak ada Data
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="3" class="text-center">
                            <a href="javascript:;" @click="rowTransferIntra.arantir_tindakan_invasif.push('')"
                              class="btn btn-outline-primary"><i class="icon-plus2 mr-2"></i>Tambah Tindakan</a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra3">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>A</h2>
                  </div>
                  <strong class="ml-2">Assessment</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra3">
              <div class="card border shadow-0">
                <div class="bg-light card-header py-2">
                  <h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
                </div>
                <div class="card-body">
                  <table class="table table-bordered">
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Skala Nyeri</h4>
                          <p>{{rowTransferIntra.arantir_skala_nyeri_value||0}}
                            <span v-if="rowTransferIntra.arantir_skala_nyeri_value == 0"
                              class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                            <span
                              v-else-if="rowTransferIntra.arantir_skala_nyeri_value >= 1 && rowTransferIntra.arantir_skala_nyeri_value < 4"
                              class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                            <span
                              v-else-if="rowTransferIntra.arantir_skala_nyeri_value >= 4 && rowTransferIntra.arantir_skala_nyeri_value < 7"
                              class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri
                              Parah</span>
                            <br />(Numeric Rating Scale)</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tingkat Kesadaran</h4>
                          <p>{{rowTransferIntra.arantir_tingkat_kesadaran_value||0}}
                            <span v-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 14"
                              class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span>
                            <span
                              v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 12 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 13"
                              class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                            <span
                              v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 10 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 11"
                              class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                            <span
                              v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 7 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 9"
                              class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                            <span
                              v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 5 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 6"
                              class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                            <span
                              v-else-if="rowTransferIntra.arantir_tingkat_kesadaran_value >= 4 && rowTransferIntra.arantir_tingkat_kesadaran_value <= 4"
                              class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                            <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                            <br />(GCS)</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Risiko Jatuh</h4>
                          <p>{{rowTransferIntra.arantir_resiko_jatuh_value||0}}
                            <span v-if="rowTransferIntra.arantir_resiko_jatuh_value < 7"
                              class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                            <span
                              v-else-if="rowTransferIntra.arantir_resiko_jatuh_value >= 7 && rowTransferIntra.arantir_resiko_jatuh_value <= 11"
                              class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                            <span v-else-if="rowTransferIntra.arantir_resiko_jatuh_value >= 12"
                              class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                            <br />
                              <span v-if="isParent.row.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                              <span v-else-if="isParent.row.ap_usia > 18 && isParent.row.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                              <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
                            </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Tekanan Darah</h4>
                          <p>
                            {{rowTransferIntra.arantir_tekanan_darah_min||"-"}}/{{rowTransferIntra.arantir_tekanan_darah_max||"-"}}
                            mmHG</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Nadi</h4>
                          <p>{{rowTransferIntra.arantir_nadi||"-"}}x per menit</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Suhu</h4>
                          <p>{{rowTransferIntra.arantir_suhu||"-"}} &deg;C</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Respiration Rate</h4>
                          <p>{{rowTransferIntra.arantir_respiration||"-"}}x per menit</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>SpO2</h4>
                          <p>{{rowTransferIntra.arantir_spo2||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4></h4>
                          <p></p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7 col-lg-5">
                  <div class="form-group">
                    <label for="">Radiologi</label>
                    <b-form-textarea v-model="rowTransferIntra.arantir_radiologi" name="" id="" rows="3"
                      class="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra4">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>R</h2>
                  </div>
                  <strong class="ml-2">Recommendation</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra4">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Tindakan yang Perlu Dilanjutkan</label>
                    <b-form-textarea v-model="rowTransferIntra.arantir_tindakan" name="" id="" rows="3"
                      class="form-control" />
                    <VValidate name="Tindakan yang Perlu Dilanjutkan" v-model="rowTransferIntra.arantir_tindakan"
                      :rules="toValidate(mrValidationTransferIntra.arantir_tindakan)" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Usulan Kolaborasi</label>
                    <b-form-textarea v-model="rowTransferIntra.arantir_usulan_kolab" name="" id="" rows="3"
                      class="form-control" />
                    <VValidate name="Usulan Kolaborasi" v-model="rowTransferIntra.arantir_usulan_kolab"
                      :rules="toValidate(mrValidationTransferIntra.arantir_usulan_kolab)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  data() {
    return {
      rowData: {},
      rowTransferIntra: {},
      mrValidationTransferIntra: {}
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    toValidate(val){
      return {...val}
    },        
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.radiologiNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.radiologiNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.radiologiNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },
    autoSaveTransferIntra: _.debounce(function (data) {
      data.type = 'auto-save-transfer-intra'
      Gen.apiRest(
          "/do/"+this.modulePage,
          {data:data}, 
          "POST"
      )
    },1000),

    

    
    doSubmit(){
        this.$refs['VFormTindakLanjut'].validate().then(success => {
            if (!success) {
                return this.$swal({
                    icon: 'error',
                    title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                }).then(result => {
                    if (result.value) {
                        setTimeout(() => {
                            let inv = []
                            let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                            for (let i = 0; i < (el || []).length; i++) {
                                if (el[i].style.display !== 'none') {
                                inv.push(el[i].id)
                                }
                            }
                            if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                        },500)
                    }
                })
            }
                    
            if (success) {
              this.$swal({
                icon: 'warning',
                title: 'Apakah Anda Yakin akan menyimpan data ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
              }).then(result => {
                if (result.value) {
                  let data = this.rowTransferIntra
                  data.type = 'submit-data-transfer-intra'
                  this.loadingOverlay = true
                  Gen.apiRest(
                  "/do/" + this.modulePage, {
                      data: data
                  },
                  "POST"
                  ).then(res => {
                      this.loadingOverlay = false
                      let resp = res.data
                      resp.statusType = 200
                      this.$swal({
                          title: resp.message,
                          icon: resp.status,
                          confirmButtonText: 'Ok',
                          allowOutsideClick: false,
                          allowEscapeKey: false
                      }).then(result => {
                        this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                      })
                  }).catch(err => {
                      this.loadingOverlay = false
                      if (err) {
                          err.statusType = err.status
                          err.status = "error"
                          err.title = err.response?.data?.title
                          err.message = err.response?.data?.message
                          err.messageError = err.message
                      }
                      this.doSetAlertForm(err)
                  })
                }
              })
            }
        })
    },
  },
  mounted() {
    this.apiGetForm()
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent
    },
    jamPindah(){
      return moment().format('HH:mm')
    }
  },
  watch: {
    rowTransferIntra: {
      handler(v) {
        this.autoSaveTransferIntra(v)
      },
      deep: true
    }
  }
}

</script>
