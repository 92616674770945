<template>
  <div>
    <validation-observer ref="VFormInformConsent">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-4 mb-2" v-for="(v,k) in (informConcent||[])" :key="k">
            <div class="wrap_line mb-0 h-100">
                <div class="mb-2">
                    <h3 class="pb-0">[{{v.aranic_created_on}}] {{v.aranic_name||"-"}}</h3>
                </div>
                <div>
                    <a href="javascript:;" @click="openTemplateIC(v)" class="btn alpha-success border-success text-success-800 mb-1">
                        <b><i class="icon-download"></i></b>
                        <span class="ml-2">Unduh Template Dokumen</span>
                    </a>
                    <Uploader @input="uploadDokStatus(v,k)" v-model="v.aranic_file" isDocument type="doc_only" />
                </div>
                
                <span class="badge badge-primary" v-if="v.aranic_created_on == 'PetugasRadiologi'">
                    Radiologi
                </span>
                <span class="badge badge-primary" v-else>
                    Dokter
                </span>

                <div class="d-flex align-items-center justify-content-between mt-2">
                    <span v-if="v.aranic_status == 'DONE' || v.aranic_file" class="badge badge-success">Selesai</span>
                    <span v-else class="badge badge-info">Unggah TTD</span>

                    <div>
                        <a v-if="v.aranic_file" :href="isParent.uploader(v.aranic_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>

                        <a href="javascript:;" @click="openInformConsentDetail(v)" data-toggle="modal" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-eye"></i></a>
                    </div>
                </div>
                
                <span :class="v.aranic_is_pasien_setuju == 'Y' ? 'badge badge-success':'badge badge-danger'">
                {{v.aranic_is_pasien_setuju == 'Y' ? 'Tindakan Disetujui':'Tindakan Ditolak'}} Oleh 
                {{v.aranic_persetujuan_tindakan == 'P' ? 'Pasien':'Penanggung Jawab'}}
                </span>
            </div>
          </div>
          <div class="col-md-4" v-if="!(informConcent||[]).length">
            <p>Tidak ada Data Inform Consent</p>
          </div>
        </div>
        
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="text-right">
            <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
            </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>

    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="xl" ok-only>
      <table class="table table-bordered table-sm table-input">
      <thead>
          <tr>
          <td colspan="3">
              <div class="result_tab">
                  <h4>Nama Tindakan</h4>
                  <p>{{rowIC.aranic_name}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <th width="24">No.</th>
          <th>Jenis Informasi</th>
          <th>Isi Informasi</th>
          </tr>
      </thead>
      <tbody>
          <tr>
          <td>1</td>
          <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
          <td>
          {{rowIC.aranic_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>2</td>
          <td>Dasar Diagnosis</td>
          <td>
          {{rowIC.aranic_dasar_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>3</td>
          <td>Tindakan Kedokteran</td>
          <td>
          {{rowIC.aranic_tindakan_dokter||"-"}}
          </td>
          </tr>
          <tr>
          <td>4</td>
          <td>Indikasi Tindakan</td>
          <td>
          {{rowIC.aranic_indikasi_tindakan||"-"}}
          </td>
          </tr>
          <tr>
          <td>5</td>
          <td>Tata Cara</td>
          <td>
          {{rowIC.aranic_tata_cara||"-"}}
          </td>
          </tr>
          <tr>
          <td>6</td>
          <td>Tujuan</td>
          <td>
          {{rowIC.aranic_tujuan||"-"}}
          </td>
          </tr>
          <tr>
          <td>7</td>
          <td>Risiko</td>
          <td>
          {{rowIC.aranic_risiko||"-"}}
          </td>
          </tr>
          <tr>
          <td>8</td>
          <td>Komplikasi</td>
          <td>
          {{rowIC.aranic_kompilasi||"-"}}
          </td>
          </tr>
          <tr>
          <td>9</td>
          <td>Prognosis</td>
          <td>
          {{rowIC.aranic_prognosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>10</td>
          <td>Alternatif & Risiko</td>
          <td>
          {{rowIC.aranic_alternatif||"-"}}
          </td>
          </tr>
          <tr>
          <td>11</td>
          <td>Lain-lain</td>
          <td>
          {{rowIC.aranic_lainnya||"-"}}
          </td>
          </tr>
      </tbody>
      <tfoot>
          <tr>
          <td width="50%" colspan="2">
              <div class="result_tab">
              <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
              <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
              <p v-else>Tindakan ditolak</p>
              </div>
          </td>
          <td width="50%">
              <div class="result_tab">
              <h4>Persetujuan tindakan diberikan kepada</h4>
              <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
              <p v-else>Penanggung Jawab</p>
              </div>
          </td>
          </tr>
      </tfoot>
      </table>
    </b-modal>

      
    <validation-observer ref="VFormModal">
    <b-modal v-model="openTemplateICModal" @ok.prevent="unduhtemplateIC(rowIC)" ok-title="Unduh Template" title="Unduh Template Dokumen" size="lg">
        <div class="col-md-12 col-12 mb-2">
          <b-form-group class="mb-0">
            <label>Dokter Pelaksana</label>
            <v-select placeholder=" -- Pilih Dokter Pelaksana -- " v-model="rowIC.dokter_pelaksana"
              :options="isParent.mDPJP" label="text" :reduce="v=>v.text"></v-select>
          </b-form-group>
        </div>
        <table class="table table-bordered table-sm text-uppercase">
            <tbody>
                <tr>
                    <td colspan="3" class="bg-primary">Data Pasien</td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Nama Pasien</h4>
                        <p>{{isParent.row.ap_fullname||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Usia</h4>
                        <p>{{isParent.row.ap_usia}} Tahun</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Alamat</h4>
                        <p>{{isParent.row.ap_address||"-"}}</p>
                        </div>
                    </td>
                </tr>
                <!-- == 'PJ'-->
                <template v-if="rowIC.aranic_persetujuan_tindakan == 'PJ'">
                <tr>
                    <td colspan="3" class="bg-primary">Data Penanggung Jawab</td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Nama Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aranic_nama_pj" type="text" name="NamaPenanggung" id="NamaPenanggung" class="form-control"
                        placeholder="Nama Penanggung Jawab"/>
                        <VValidate 
                            name="Nama Penanggung Jawab" 
                            v-model="rowIC.aranic_nama_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Usia Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aranic_usia_pj" type="text" name="UsiaPenanggung" id="UsiaPenanggung"
                          class="form-control" placeholder="Usia Penanggung Jawab" />
                        <VValidate name="Usia Penanggung Jawab" v-model="rowIC.aranic_usia_pj" :rules="{required: 1}" />
                      </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Alamat Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aranic_address_pj" type="text" name="alamat" id="alamat" class="form-control"
                        placeholder="Alamat Penanggung Jawab"/>
                        <VValidate 
                            name="Alamat Penanggung Jawab" 
                            v-model="rowIC.aranic_address_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                </tr>
                </template>
            </tbody>
        </table>
    </b-modal>
    </validation-observer>

  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  data(){
    return {
      openInformConsent: false,
      informConcent: [],
      rowIC: {},
      openModalICDetail: false,
      dataPJ: {},
      openTemplateICModal: false,
    }
  },
  methods: {
    
    selectedInformConsent(dataRaw){
      let data = []
      for(let i = 0; i < (dataRaw.arantr_data||[]).length; i++){
        for(let j = 0; j < (dataRaw.arantr_data[i]['data']||[]).length; j++){  
            if(dataRaw.arantr_data[i]['data'][j]['selected'] && dataRaw.arantr_data[i]['data'][j]['inform_consent']){
              data.push(dataRaw.arantr_data[i]['data'][j]['inform_consent'])
            }
        }
      }
      return data.join(", ")
    },
    getData(){
      this.isParent.loadingOverlay = true
      let data = this.isParent.rowEdit
      data.id = this.isParent.rowEdit.arantr_id
      data.aranr_id = this.isParent.rowEdit.arantr_aranr_id
      data.type = 'get-inform-consent'
      data.selectedIC = this.selectedInformConsent(this.isParent.rowEdit)

      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data: data}, 
          "POST"
      ).then(res=>{
        let resp = res.data
        this.isParent.loadingOverlay = false
        this.informConcent = resp.informConcent
        this.isParent.rowEdit = resp.rowEdit
      })
    },
    changeTab(tab){
      this.activeTab = tab
    },

    // inform consent
    openInformConsentDetail(v){
        Gen.apiRest('/do/' + this.isParent.modulePage, {
            data: {
              type: 'get-data-ic-single',
              id: v.aranic_id,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.openModalICDetail = true                   
        })
    },
    openTemplateIC(v){
      this.rowIC = v
      this.rowIC.aranic_nama_pj = this.rowIC.aranic_nama_pj||this.isParent.row.arpj_fullname
      this.rowIC.aranic_address_pj = this.rowIC.aranic_address_pj||this.isParent.row.arpj_address
      this.rowIC.aranic_usia_pj = this.rowIC.aranic_usia_pj||this.isParent.row.ap_usia_with_ket
      this.openTemplateICModal = true
      this.$set(this.rowIC, 'dokter_pelaksana', this.rowIC.dokter_pelaksana||this.isParent.rowReg.bu_full_name)

    },
  
    uploadDok(v,k=''){
      Gen.apiRest('/do/' + this.isParent.modulePage, {
        data: {
            type: 'update-data-ic',
            id: v.aranic_id,
            aranic_file: v.aranic_file,
            aranic_nama_pj: v.aranic_nama_pj,
            aranic_address_pj: v.aranic_address_pj,
            aranic_usia_pj: v.aranic_usia_pj,
        }
      }, 'POST').then(res => {
        if(k){
            this.informConcent[k]['aranic_status'] = 'DONE' 
        }
      })
    },
    unduhtemplateIC(v){
      this.$refs['VFormModal'].validate().then(success => {
        if(success){
            this.uploadDok(v)                
            setTimeout(()=>{
                this.openTemplateICModal = false
                let data = {exptype: 'xlsx', type: "export", id: v.aranic_id, regID: this.isParent.rowEdit.arantr_aranr_id, dokter_pelaksana: v.dokter_pelaksana}
                let self = this
                self.isParent.loadingOverlay = true
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'InformConsentRanap'}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.isParent.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${'Inform-consent'}-${this.isParent.row.ap_fullname}-${moment().format("YYYY-MM-DD")}-${this.isParent.rowReg.aranr_reg_code}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.isParent.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },2000)
            
        }
      })
    },
    uploadDokStatus(v,k=''){
      Gen.apiRest('/do/' + this.isParent.modulePage, {
        data: {
            type: 'update-data-ic',
            id: v.aranic_id,
            aranic_file: v.aranic_file,
            aranic_nama_pj: v.aranic_nama_pj,
            aranic_address_pj: v.aranic_address_pj,
            aranic_status: 'DONE'
        }
      }, 'POST').then(res => {
          this.informConcent[k]['aranic_status'] = 'DONE' 
      })
    },
    // inform consent

    doSubmit(){
      this.$refs['VFormInformConsent'].validate().then(success=>{
        if (!success) {
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          })
        }
        if(success){
          this.$swal({
            title: "Berhasil Mengunggah Inform Consent",
            icon: "success",
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          this.isParent.openModal = false
        }
      })
    },

  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted() {
    this.getData()
  }
  
}

</script>